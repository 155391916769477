export const homeObjOne = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'HOME LAND NY REAL ESTATE AGENCY',
    headline: 'GET A FREE QUOTE ON YOUR PROPERTY RIGHT AWAY',
    description:
      'Ready to sell your property with ease? We have got you covered. Whether residential, commercial, vacant land , or a fixer-upper, we are interested. Cash offers and "as-is" deals are our specialties. Reach out to us today.',
    buttonLabel: 'Get Started',
    buttonLink: '/sign-up',
    imgStart: '',
    img: require('../../images/newnewonsale.png'),
    alt: 'Credit Card',
    start: '',
    backgroundColor: '#000000', // Change this color code to the desired background color

  };
  
  export const homeObjTwo = {
    sectionTitle: 'OUR PROJECTS',
    primary: true,
    lightBg: true,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Instant Setup',
    headline: 'Extremely quick onboarding process',
    description:
      "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/svg-2.svg'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline:
      'Bungalow in Long Beach',
    description:
      "bought this place last year and renovated it",
    buttonLabel: 'View Project',
    imgStart: 'start',
    img: require('../../images/longbeachsecond.jpeg'),
    alt: 'Vault',
    start: 'true',
    sectionTitle:"OUR PROJECTS",
  };
  
  export const homeObjFour = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine:'',
    headline: 'House in Bellmore',
    description:
      'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
    buttonLabel: 'Sign Up Now',
    buttonLink: '/sign-up',
    imgStart: 'start',
    img: require('../../images/Bellmore.jpeg'),
    alt: 'Vault',
    start: 'true'
  };