import React from 'react';
import { Button } from '../../globalStyles';
import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  Form,
  BtnLink,
} from './Footer.elements';

function Footer() {

  return (
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>
        Available anytime for any of your concerns.
        </FooterSubHeading>
        <FooterSubText>We're here  to see how we can help!</FooterSubText>
        <Form>
          <BtnLink to='/sign-up' >
            <Button fontBig>Contact Us</Button>
          </BtnLink>
        </Form>
      </FooterSubscription>
    </FooterContainer>
  );
}

export default Footer;