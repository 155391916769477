import React from 'react';
import { InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img, SectionSubtitle, SectionTitle } from './InfoSection.elements';
import { Container, Button } from '../../globalStyles';
import { Link } from 'react-router-dom';

const InfoSection = ({
    id,
    sectionTitle,
    sectionSubtitle,
    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    buttonLink,
    img,
    alt,
    imgStart,
    start,
    showButton // Add this prop to control the visibility of the button
}) => {

    return (
        <>
            <InfoSec id={id} lightBg={lightBg}>
                {sectionTitle && <SectionTitle lightText={lightText}>{sectionTitle}</SectionTitle>}
                {sectionSubtitle && <SectionSubtitle lightTextDesc={lightTextDesc}>{sectionSubtitle}</SectionSubtitle>}
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                                {showButton && (
                                    <Link to={buttonLink}>
                                        <Button big fontBig primary={primary}>
                                            {buttonLabel}
                                        </Button>
                                    </Link>
                                )}
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <ImgWrapper start={start}>
                                <Img src={img} alt={alt} />
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    );
}

export default InfoSection;
