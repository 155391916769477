import React from 'react';
import { InfoSection } from '../../components';
import { homeObjOne, homeObjThree, homeObjFour } from './Data';
import HowItWorks from "../HowItWorks/HowItWorks";

const Home = () => {
    return (
        <>
            <InfoSection {...homeObjOne} showLogo={true} showButton={true}/>
            <HowItWorks id="how-it-works"/>
            <InfoSection id="projects" {...homeObjThree} showLogo={false} showButton={false}/>
            <InfoSection {...homeObjFour}/>


        </>
    );
}

export default Home;
