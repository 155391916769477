import React from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Nav, NavbarContainer, NavLogo, HamburgerIcon, NavMenu, NavItem, NavLinks, NavItemBtn, NavBtnLink, Logo } from './Navbar.elements';
import { Button } from '../../globalStyles';

const Navbar = () => {
    const [click, setClick] = React.useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const scrollToTop = () => {
               window.scrollTo({ top: 0, behavior: 'smooth' });
                closeMobileMenu(); // Close the mobile menu after clicking on a link
            };
    return (
        <IconContext.Provider value={{ color: '#fff' }}>
            <Nav>
                <NavbarContainer>
                    <NavLogo to='/' onClick={scrollToTop}>
                    <Logo />
                        HOME LAND NY
                    </NavLogo>
                    <HamburgerIcon onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </HamburgerIcon>
                    <NavMenu onClick={handleClick} click={click}>
                        <NavItem>
                            <NavLinks href='/' onClick={closeMobileMenu}>
                                Home
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                        <NavLinks href='/#how-it-works' onClick={closeMobileMenu}>
                                    How it Works
                                </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks href='/#projects' onClick={closeMobileMenu}>
                                Our Projects
                            </NavLinks>
                        </NavItem>
                        <NavItemBtn>
                            <NavBtnLink to='/sign-up'>
                                <Button primary>Contact Us</Button>
                            </NavBtnLink>
                        </NavItemBtn>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
    );
};

export default Navbar;


