import React from 'react';
import { FilloutStandardEmbed } from "@fillout/react";
import styled from 'styled-components';


function SignUp() {
  return (
    <Frame>
      <FilloutStandardEmbed filloutId="49UnErZobfus" />
    </Frame>
  );
}

export default SignUp;

const Frame = styled.div`
  width: 100%; // Ensures the container takes the full width of the parent
  height: 1000px; // Fixed height
`;
