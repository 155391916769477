import React from 'react';
import RowSection from "../../components/RowSection/RowSection";
import head from "../../images/head.png"
import cash from "../../images/cash.png"
import savemoney from "../../images/savemoney.png"
import work from "../../images/work.png"
import yourschedule from "../../images/yourschedule.png"
import housekey from "../../images/housekey.png"

const HowItWorks = ({ id }) => {
    const rows = [
        {
            text: 'Facing challenges like foreclosure or property damage? Life happens, but we are here to help.',
            image: head,
        },
        {
            text: 'Receive a guaranteed CASH offer within 24 hours - no strings attached.',
            image: cash,
        },
        {
            text: 'No brokers, contracts, or intrusive showings. Plus, forget about commissions and fees.',
            image: savemoney,
        },
        {
            text: 'Sell your property AS-IS - no repairs, no hassle with contractors, permits, or inspections.',
            image: work,
        },
        {
            text: 'Close in as little as 30 days on average. Need more time? We are flexible and work on your schedule.',
            image: yourschedule,
        },
        {
            text: 'Do not let circumstances hold you back. With cash in hand, live your best life.',
            image: housekey,
        },
    ];

    return (
        <RowSection
            lightBg={false}
            lightText={true}
            lightTextDesc={true}
            rows={rows}
            img require={'../../images'}
            id={id}
            sectionTitle="HOW IT WORKS"
        />
    );
}

export default HowItWorks;
